import * as React from "react";
import Layout from "../components/Layout";
import { useStaticQuery, graphql } from "gatsby";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      homeCover: file(relativePath: { eq: "cover.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  return (
    <Layout
      imageDesktop={data.homeCover.childImageSharp.fluid}
      imageMobile={data.homeCover.childImageSharp.fluid}
    >
      <div className="flex fixed w-full top-1/3 place-content-center">
        <div className="flex flex-col text-sky-offwhite place-content-center">
          <h1 className="text-center text-6xl">404</h1>
          <h2 className="text-center text-4xl">Page not found :(</h2>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
